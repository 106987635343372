import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Image } from 'react-bootstrap';
import Logo from '../../assets/images/logo.svg';
import './header.scss';

class Header extends Component {
  componentDidMount() {
    this.fixbar();
  }
  fixbar = () => {
    const header = document.getElementById("header");
    const shrinkHeader = header.offsetTop + header.clientHeight;
    window.addEventListener("scroll", function(event) {
      const scroll = getCurrentScroll();
      if (scroll >= shrinkHeader) {
        header.classList.add("header-fix");
      } else {
        header.classList.remove("header-fix");
      }
    });
    function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    }
  };
  render(){
    return (
      <header id="header">
        <Navbar expand="lg">
        <Navbar.Brand href="#home"><Image src={Logo} alt="logo"></Image></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto ml-auto">
            <Link className="nav-link" activeClassName="active" to="/">Home</Link>
            <Link className="nav-link" activeClassName="active" to="#about">About Us</Link>
            <Link className="nav-link" activeClassName="active" to="#services">Services</Link>
            <Link className="nav-link" activeClassName="active" to="solution">Solutions</Link>
            <Link className="nav-link" activeClassName="active" to="#work">Work</Link>
            <Link className="nav-link" activeClassName="active" to="#contact">Contact</Link>
          </Nav>
          <div className="socialHead">
            <Link target="_blank" to="https://www.linkedin.com/company/wiztech-pakistan/"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 37.6C29.7202 37.6 37.6 29.7202 37.6 20C37.6 10.2798 29.7202 2.4 20 2.4C10.2798 2.4 2.4 10.2798 2.4 20C2.4 29.7202 10.2798 37.6 20 37.6ZM11.2 16.0944V27.7265H14.9681V16.0944H11.2ZM13.3318 10.4C12.0427 10.4 11.2 11.2687 11.2 12.4092C11.2 13.5261 12.0178 14.4198 13.2828 14.4198H13.3069C14.6208 14.4198 15.4391 13.5261 15.4391 12.4092C15.4146 11.2687 14.6208 10.4 13.3318 10.4ZM24.4615 16.0944C22.4609 16.0944 21.5652 17.2235 21.0651 18.0155V16.3679H17.2966C17.3463 17.4594 17.2966 28 17.2966 28H21.0651V21.5037C21.0651 21.156 21.0896 20.8092 21.1892 20.5603C21.4615 19.8658 22.0816 19.1467 23.1226 19.1467C24.4867 19.1467 25.0319 20.2134 25.0319 21.7765V27.9997H28.7999L28.8 21.3302C28.8 17.7573 26.9411 16.0944 24.4615 16.0944Z" fill="white"/></svg></Link>
            <Link target="_blank" to="https://www.facebook.com/wiztechpakistan"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 37.6C29.7202 37.6 37.6 29.7202 37.6 20C37.6 10.2798 29.7202 2.4 20 2.4C10.2798 2.4 2.4 10.2798 2.4 20C2.4 29.7202 10.2798 37.6 20 37.6ZM21.4516 28.8V19.999H24.055L24.4 16.9661H21.4516L21.456 15.4481C21.456 14.6571 21.5366 14.2332 22.754 14.2332H24.3816V11.2H21.7778C18.6503 11.2 17.5495 12.6713 17.5495 15.1455V16.9664H15.6V19.9993H17.5495V28.8H21.4516Z" fill="white"/></svg></Link>
          </div>
        </Navbar.Collapse>
      </Navbar>
      </header>
  );
  }

}

export default Header;
