
import React, { Component } from 'react';
import AnimatedCursor from "react-animated-cursor"
// import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css'
import 'aos/dist/aos.css'
import 'jquery'
import AOS from 'aos/dist/aos.js'
import './index.scss';

import Loader from '../components/loader/loader'

/* eslint-disable-next-line */
export interface IndexProps {}

export class Index extends Component {
  state = { loading: true };
  sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };
  wait = async (milliseconds = 2000) => {
    await this.sleep(milliseconds);
    this.setState({
      loading: false
    });
  };
  componentDidMount() {
    this.wait();
    // this.fetchGitHub();

    AOS.init({
      once: true
    });
  }
  render() {
    const { children } = this.props;
    if (this.state.loading) {
      return <Loader />
    }else {
      return (
        <div>
          <AnimatedCursor
            innerSize={12}
            outerSize={12}
            color='35, 100, 173'
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={5}
          />
            {children}
        </div>
      );
    }
  }
}

export default Index;
