import React from 'react';

import './loader.scss';

/* eslint-disable-next-line */
export interface LoaderProps {}

export function Loader(props: LoaderProps) {
  return (
    <div className="mesh-loader">
      <div className="set-one">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
      <div className="set-two">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
}

export default Loader;
